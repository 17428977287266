import React, { useEffect, useState } from "react";

import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import { graphql } from "gatsby";
import axios from "axios";

import { GetinTouchContainer } from "components/common/Structure";
import Footer from "components/footer";
import Popup from "components/popup";

const GetinTouch = (props) => {
  //PROPS
  const {
    data: {
      page: {},
    },
    setColor,
  } = props;
  //PROPS

  //PAGEANIM
  const getinWrapper = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { delay: 0.5, duration: 1 },
    },
    exit: {
      opacity: 0,
      transition: { ease: "easeInOut", duration: 1 },
    },
  };
  //PAGEANIM

  //DEFS
  const lang =
    props.pageContext.translations[0].language.code === "EN" ? "PT" : "EN";

  let isPt = props.pageContext.translations[0].language.code === "EN";
  //DEFS

  //POPSTATE
  const [response, setResponse] = useState({
    type: "",
    message: "",
  });

  const [isOpen, setisOpen] = useState(false);

  const handleClick = () => {
    setisOpen(!isOpen);
  };
  //POPSTATE

  //FORMHANDLING
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const clearForm = () => {
    document.getElementById("form").reset();
  };

  const onSubmit = async (e) => {
    handleClick();
    sendMessage();
  };

  const sendMessage = () => {
    setResponse({
      type: "loading",
      message: isPt ? "A enviar ..." : "Sending ...",
    });

    var contactsFormData = new FormData();

    contactsFormData.append("fname", document.querySelector("#fname").value);
    contactsFormData.append("femail", document.querySelector("#femail").value);
    contactsFormData.append("fphone", document.querySelector("#fphone").value);
    contactsFormData.append(
      "fbrand",
      document.querySelector("#fbrand").checked ? "true" : "false"
    );
    contactsFormData.append(
      "fweb",
      document.querySelector("#fweb").checked ? "true" : "false"
    );
    contactsFormData.append(
      "fmd",
      document.querySelector("#fmd").checked ? "true" : "false"
    );
    contactsFormData.append(
      "fdi",
      document.querySelector("#fdi").checked ? "true" : "false"
    );
    contactsFormData.append(
      "farq",
      document.querySelector("#farq").checked ? "true" : "false"
    );
    contactsFormData.append(
      "fmessage",
      document.querySelector("#fmessage").value
    );

    var config = {
      method: "post",
      url: `https://staging.devstudio.com.pt/wp/wp-json/contact-form-7/v1/contact-forms/676/feedback`,
      headers: {
        "Content-Type": "application/json",
      },
      data: contactsFormData,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === "mail_sent") {
          setResponse({
            type: "success",
            message: isPt
              ? "Obrigado pelo seu contacto. Vamos tentar responder assim que possível."
              : "Thank you for reaching out, we'll try to answer as soon as possible.",
          });
          clearForm();
        } else {
          setResponse({
            type: "error",
            message: isPt
              ? "Algo de errado aconteceu. Tenta outra vez por favor. Senão manda-nos diretamente um email."
              : "Something wrong happened. Please try again ou send us an email.",
          });
        }
      })
      .catch(function (error) {
        setResponse({
          type: "error",
          message: isPt
            ? "Algo de errado aconteceu. Tenta outra vez por favor. Senão manda-nos diretamente um email."
            : "Something wrong happened. Please try again ou send us an email.",
        });
      });
  };
  //FORMHANDLING

  useEffect(() => {
    setColor(true);
  }, []);

  return (
    <>
      <motion.div
        variants={getinWrapper}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="homeWrapper"
      >
        <Popup
          show={isOpen}
          toggle={() => setisOpen()}
          type={response.type}
          message={response.message}
        />
        <GetinTouchContainer>
          <div className="container">
            <div className="info">
              <h1>
                {" "}
                {isPt
                  ? "Juntos vamos criar algo espetacular."
                  : "Let’s create something amazing."}
              </h1>
              <p>
                Alameda do Mosteiro, 48 <br />
                4590 - 504, Paços de Ferreira
              </p>

              <p>
                {isPt
                  ? "916 010 181 ou 916 197 017"
                  : "+351 916 010 181 or +351 916 197 017"}
              </p>
            </div>
            <form className="form" id="form" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <input
                  placeholder={isPt ? "O seu nome *" : "Your name *"}
                  id="fname"
                  type="text"
                  {...register("fname", { required: true })}
                />

                {errors.fname && (
                  <p>
                    {isPt ? "Precisamos do seu nome." : "We need your name."}
                  </p>
                )}
              </div>
              <div>
                <input
                  placeholder={isPt ? "O seu email *" : "Your email *"}
                  id="femail"
                  type="text"
                  {...register("femail", { required: true })}
                />

                {errors.femail && (
                  <p>
                    {isPt ? "Precisamos do seu email." : "We need your email."}
                  </p>
                )}
              </div>
              <div>
                <input
                  placeholder={isPt ? "O seu telemóvel *" : "Your phone *"}
                  id="fphone"
                  type="text"
                  {...register("fphone", { required: true })}
                />

                {errors.fphone && (
                  <p>
                    {isPt
                      ? "Precisamos do seu contacto telefónico."
                      : "We need your phone number."}
                  </p>
                )}
              </div>
              <div>
                <p style={{ padding: "10px" }}>
                  {isPt
                    ? "Que amostras gostava de receber?"
                    : "Which samples would you like to receive?"}
                </p>
                <div className="select">
                  <label className="select-label">
                    <input type="checkbox" id="fbrand" />
                    <span className="checkmark" />
                    Branding
                  </label>
                  <label className="select-label">
                    <input type="checkbox" id="fweb" />
                    <span className="checkmark" />
                    Webdesign
                  </label>
                  <label className="select-label">
                    <input type="checkbox" id="fmd" />
                    <span className="checkmark" />
                    {isPt ? "Marketing Digital" : "Digital Marketing"}
                  </label>
                  <label className="select-label">
                    <input type="checkbox" id="fdi" />
                    <span className="checkmark" />
                    {isPt ? "Design Interiores" : "Interior Design"}
                  </label>
                  <label className="select-label">
                    <input type="checkbox" id="farq" />
                    <span className="checkmark" />
                    {isPt ? "Arquitectura" : "Architecture"}
                  </label>
                </div>
              </div>
              <div>
                <textarea
                  placeholder={isPt ? "A sua mensagem *" : "Your message *"}
                  id="fmessage"
                  type="text"
                  {...register("fmessage", { required: true })}
                />

                {errors.fmessage && (
                  <p>
                    {isPt
                      ? "Precisamos de uma mensagem."
                      : "We need a message."}
                  </p>
                )}
              </div>
              <div className="submit-button">
                <button>{isPt ? "submeter" : "submit"}</button>
              </div>
            </form>
          </div>
        </GetinTouchContainer>
        <Footer lang={lang} />
      </motion.div>
    </>
  );
};

export const query = graphql`
  query GetInTouch($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
    }
  }
`;

export default GetinTouch;
